function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

!function (e, n) {
  "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) && "undefined" != typeof module ? n() : "function" == typeof define && define.amd ? define(n) : n();
}(0, function () {
  "use strict";

  function e(e) {
    function n() {
      var d = Date.now() - l;
      d < i && d >= 0 ? r = setTimeout(n, i - d) : (r = null, t || (f = e.apply(u, o), u = null, o = null));
    }

    var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 100,
        t = arguments[2],
        r = void 0,
        o = void 0,
        u = void 0,
        l = void 0,
        f = void 0,
        d = function d() {
      u = this;

      for (var d = arguments.length, a = Array(d), s = 0; s < d; s++) {
        a[s] = arguments[s];
      }

      o = a, l = Date.now();
      var c = t && !r;
      return r || (r = setTimeout(n, i)), c && (f = e.apply(u, o), u = null, o = null), f;
    };

    return d.clear = function () {
      r && (clearTimeout(r), r = null);
    }, d.flush = function () {
      r && (f = e.apply(u, o), u = null, o = null, clearTimeout(r), r = null);
    }, d;
  }

  var n = window.jQuery;
  if (!n) throw new Error("resizeend require jQuery");
  n.event.special.resizeend = {
    setup: function setup() {
      var i = n(this);
      i.on("resize.resizeend", e.call(null, function (e) {
        e.type = "resizeend", i.trigger("resizeend", e);
      }, 250));
    },
    teardown: function teardown() {
      n(this).off("resize.resizeend");
    }
  };
});