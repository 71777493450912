/**
 * Animate value
 *
 * @param {string} selector
 * @param {number} [initVal=0]
 * @param {number} lastVal
 * @param {number} [duration=1000]
 */
function animateValue(selector, initVal, lastVal, duration) {
  var container = document.querySelector(selector);

  if (!container) {
    console.error('Add a selector to use this widget', container);
    return;
  }

  initVal = initVal || 0;
  duration = duration || 1000;
  var startTime = null; //get the current timestamp and assign it to the currentTime variable

  var currentTime = Date.now(); //pass the current timestamp to the step function

  var step = function step(currentTime) {
    //if the start time is null, assign the current time to startTime
    if (!startTime) {
      startTime = currentTime;
    } //calculate the value to be used in calculating the number to be displayed


    var progress = Math.min((currentTime - startTime) / duration, 1); //calculate what to be displayed using the value gotten above

    container.innerText = Math.floor(progress * (lastVal - initVal) + initVal); //checking to make sure the counter does not exceed the last value (lastVal)

    if (progress < 1) {
      window.requestAnimationFrame(step);
    } else {
      window.cancelAnimationFrame(window.requestAnimationFrame(step));
    }
  }; //start animating


  window.requestAnimationFrame(step);
}