// Pour l'ajout d'image dans les News
$('.add-file-selector').collection({
  allow_up: false,
  allow_down: false,
  add: '<a href="#" class="btn btn-success">Ajouter</a>',
  remove: '<a href="#" class="btn btn-warning">Supprimer</a>'
});
$(document).ready(function () {
  console.log('newrest');
  $(".deleteprocedurecat").click(function () {
    console.log('newrestclick');

    if (confirm("Ceci supprimera la catégorie ainsi que toutes les procédures associées. Êtes vous sûr ?")) {
      alert('yo');
    } else {
      return false;
    }

    return false;
  });
});